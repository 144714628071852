.partner-type-list-container {
  display: flex;
  align-items: center;
  &__autocomplete {
    flex-grow: 1;
  }
}

.partner-type-list-option {
  display: flex;
  width: 100%;
  align-items: center;

  &__content {
    flex: 1;
  }
}
