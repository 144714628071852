html {
  scroll-behavior: smooth;
  line-height: normal;
  font-size: 90%;
}
body {
  font-family: $primary-font-family !important;
  scroll-behavior: smooth;
  height: 100%;
}
html *,
html *::after,
html *::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  letter-spacing: 0.5px;
  font-family: inherit;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

#root {
  height: 100%;
}

.chrome-picker {
  box-shadow: none !important;
}
