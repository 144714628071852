.landing-page {
  min-height: 100vh;
}

.landing-footer {
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__socials {
    img {
      height: 48px;
      width: 48px;
      margin: 8px;
    }
  }

  &__background {
    height: 300px;
    width: 100%;
    position: relative;

    &__character {
      position: absolute;
      min-height: 100px;
      min-width: 100px;
      left: 0;
      bottom: 0;
      background-size: contain;
    }
  }
}
